import React from "react";
import { Button, Card, ProgressBar } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { PlusLg, CloudArrowUp, XLg, ArrowLeft } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

import { Api } from "../../common/api/Api";
import { useAppSelector } from "../../../hooks";

export default function DocumentsAdd(): React.JSX.Element {
  /**
   * Select and upload documents to the Manual server.
   */

  const navigate = useNavigate();

  const inputFieldRef = React.useRef(null);
  // Reference to the grid, so we can access its API methods.
  const gridRef = React.useRef<AgGridReact<IDocumentRowData>>(null);
  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);

  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [isShowingLoadingOverlay, setIsShowingLoadingOverlay] =
    React.useState<boolean>(false);
  const [progress, setProgress] = React.useState<number>(0);
  const [errorCount, setErrorCount] = React.useState<number>(0);
  const [isGridReady, setIsGridReady] = React.useState<boolean>(false);

  const supportedDocumentTypes: string[] = [
    "application/pdf",
    "text/plain",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  const handleDocumentDelete = (currentRowData: IDocumentRowData): void => {
    /**
     * Delete the document from the row data.
     */
    // TODO (Rob): Compare based on document hash?
    setRowData(
      rowData.filter(
        (item) =>
          !(
            item.name === currentRowData.name &&
            item.size === currentRowData.size
          ),
      ),
    );
  };

  const DeleteButtonComponent = (props: { data: IDocumentRowData }) => {
    const _currentRowData: IDocumentRowData = props.data;
    return (
      <Button
        title={"Document deselecteren"}
        variant="light"
        type="button"
        size={"sm"}
        className={"rounded-pill text-dark mb-1 py-1"}
        onClick={() => handleDocumentDelete(_currentRowData)}
        disabled={isUploading || isShowingLoadingOverlay}
      >
        <span className={"d-flex align-items-center py-1"}>
          <XLg />
        </span>
      </Button>
    );
  };

  const columnDefs = [
    {
      field: "name",
      headerName: "Naam",
      flex: 8,
      cellClass: "text-truncate",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
        filterPlaceholder: "Zoek in documenten...",
        filterOptions: ["contains"],
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
    },
    { field: "extension", headerName: "Type", flex: 2 },
    // {field: "size", headerName: "Grootte (bytes)", flex: 2},
    {
      field: "actions",
      headerName: "Acties",
      flex: isScreenLg ? 2 : 3,
      cellRenderer: DeleteButtonComponent,
    },
  ];
  const [rowData, setRowData] = React.useState<IDocumentRowData[]>([]);

  React.useEffect(() => {
    /**
     * Hide certain columns on smaller screens.
     */
    const columnsToHide: string[] = ["extension"];
    if (isGridReady) {
      if (isScreenLg) {
        gridRef.current?.api?.setColumnsVisible(columnsToHide, true);
      } else {
        gridRef.current?.api?.setColumnsVisible(columnsToHide, false);
      }
    }
  }, [isGridReady, isScreenLg]);

  const handleDocumentsChange = (
    event: React.ChangeEvent | React.BaseSyntheticEvent,
  ): void => {
    /**
     * Add documents to the row data, when the documents input field changes.
     * Format the document name (lowercase the extension).
     * Check if the document type is supported.
     */
    const newDocuments: FileList = event.target.files;
    Array.from(newDocuments).forEach((document: File) => {
      // Check if the document type is supported.
      if (supportedDocumentTypes.includes(document.type)) {
        // Format the document name (lowercase extension).
        const documentName: string = document.name.split(".").shift()!;
        const documentExtension: string = document.name
          .split(".")
          .pop()!
          .toLowerCase();
        const documentNameFormatted: string = `${documentName}.${documentExtension}`;
        // Prepare the row data for the document.
        const _currentRowData: IDocumentRowData = {
          name: documentNameFormatted,
          extension: documentExtension,
          processStatus: "submitted",
          size: document.size,
          // Keep the source document, for uploading (also with the name formatted).
          document: new File([document], documentNameFormatted, {
            type: document.type,
          }),
        };
        // Check that document isn't already in the row data.
        // TODO (Rob): Compare based on document hash?
        if (
          !rowData.find(
            (item) =>
              item.name === _currentRowData.name &&
              item.size === _currentRowData.size,
          )
        ) {
          // Add the document to the row data (parsed as row data and also keeping the source document).
          setRowData((rowData) => [...rowData, _currentRowData]);
        }
      }
    });
    // Reset the input field.
    event.target.value = null;
  };

  const handleUpload = async (): Promise<void> => {
    /**
     * Prepare and upload documents. Process each document individually.
     */
    // Show the loading overlay.
    gridRef.current!.api.showLoadingOverlay();
    setIsShowingLoadingOverlay(true);
    setIsUploading(true);
    setErrorCount(0);
    // Loop through the row data and try to upload each document.
    for (let i = 0; i < rowData.length; i++) {
      // Calculate the progress.
      const progress: number = 100 * ((i + 1) / rowData.length);
      setProgress(progress);
      // Upload the document.
      const document: File = rowData[i].document;
      const data: FormData = new FormData();
      data.append("file", document);
      await Api.post("documents/", data).then(
        () => {
          // TODO (Rob): Remove the document from the grid?
        },
        () => {
          // Update the document's name with the error in the grid.
          const _error_message: string = "(Error)";
          const _currentRowData: IDocumentRowData = rowData[i];
          _currentRowData.name = _currentRowData.name.replace(
            _error_message,
            "",
          );
          _currentRowData.name = `${_error_message} ${_currentRowData.name}`;
          setErrorCount((previousErrorCount) => previousErrorCount + 1);
        },
      );
    }
    setIsUploading(false);
  };

  const NoRowsOverlayComponent = () => {
    return (
      <div style={{ pointerEvents: "auto" }}>
        <p>Selecteer documenten om te uploaden.</p>
        <div className={"d-flex justify-content-center"}>
          <Button
            // XXX (Rob): Empty title here, to avoid the faulty tooltip (seems to be a bug).
            title={""}
            variant="dark"
            type="button"
            // @ts-expect-error:next-line
            onClick={() => inputFieldRef.current.click()}
            className={"d-flex align-items-center rounded-3"}
          >
            <PlusLg className={"me-2"} />
            Documenten selecteren
          </Button>
        </div>
      </div>
    );
  };

  const LoadingOverlayComponent = (props: {
    errorCount: number;
    progress: number;
    isUploading: boolean;
  }) => {
    const _closeOverlay = () => {
      gridRef.current!.api.hideOverlay();
      setIsShowingLoadingOverlay(false);
      setRowData([]);
      setProgress(0);
      navigate("/dashboard/documents");
    };
    return (
      <div style={{ width: "25rem" }}>
        <Card className={"bg-white rounded-3 border-1"}>
          <Card.Header className={"p-3 text-start rounded-3 border-0 bg-white"}>
            <Card.Title className={"mb-0"}>
              <h5 className={"mb-0"}>Documenten uploaden</h5>
            </Card.Title>
          </Card.Header>
          <Card.Body className={"p-3 text-start border-0"}>
            <p className={"m-0"}>
              {props.isUploading ? (
                <span>Documenten worden geupload...</span>
              ) : (
                <span>
                  Documenten zijn geupload
                  {props.errorCount === 1
                    ? ` (1 document kon niet verwerkt worden)`
                    : ""}
                  {props.errorCount > 1
                    ? ` (${props.errorCount} documenten konden niet goed verwerkt worden)`
                    : ""}
                  .
                </span>
              )}
            </p>
          </Card.Body>
          <Card.Footer
            className={"rounded-bottom-3 border-0 p-3 bg-white"}
            style={{ minHeight: "3.125rem" }}
          >
            {props.isUploading ? (
              <div>
                <ProgressBar
                  min={0}
                  now={props.progress}
                  max={100}
                  animated={true}
                  variant={"dark"}
                  className={"my-2 rounded-3"}
                />
              </div>
            ) : (
              <div className="d-flex justify-content-end">
                <Button
                  title={"Ok"}
                  variant="dark"
                  className={"rounded-3"}
                  disabled={props.isUploading}
                  onClick={_closeOverlay}
                >
                  Ok
                </Button>
              </div>
            )}
          </Card.Footer>
        </Card>
      </div>
    );
  };

  return (
    <div className={"h-100 d-flex flex-column"}>
      <div className={"d-flex align-items-center mb-3 py-1 text-muted"}>
        <Button
          title={"Vorige"}
          name={"Vorige"}
          type="button"
          onClick={() => navigate(-1)}
          className={"d-flex me-2 border-0 bg-transparent text-muted p-0"}
        >
          <ArrowLeft />
        </Button>
        <h6
          onClick={() => navigate("/dashboard/documents")}
          className={"m-0 ms-1 text-decoration-underline"}
          style={{ cursor: "pointer" }}
        >
          Documenten
        </h6>
        <h6 className={"m-0 mx-2"}>/</h6>
        <h6 className={"m-0 text-truncate"}>Upload</h6>
      </div>

      <input
        ref={inputFieldRef}
        name="documents"
        type="file"
        multiple={true}
        accept={supportedDocumentTypes.join(",")}
        onChange={handleDocumentsChange}
        hidden={true}
      />

      <div className="ag-theme-quartz h-100" style={{ minHeight: "15rem" }}>
        <AgGridReact<IDocumentRowData>
          ref={gridRef}
          onGridReady={() => setIsGridReady(true)}
          // @ts-expect-error:next-line
          columnDefs={columnDefs}
          rowData={rowData}
          reactiveCustomComponents={true}
          noRowsOverlayComponent={NoRowsOverlayComponent}
          loadingOverlayComponent={LoadingOverlayComponent}
          loadingOverlayComponentParams={{
            progress: progress,
            isUploading: isUploading,
            errorCount: errorCount,
          }}
          suppressCellFocus={true}
        />
      </div>

      {rowData.length > 0 ? (
        <div className={"d-flex justify-content-between mt-3"}>
          <div className={"rounded-2 bg-white"}>
            <Button
              title={"Meer documenten selecteren"}
              variant="light"
              type="button"
              // @ts-expect-error:next-line
              onClick={() => inputFieldRef.current.click()}
              className={
                "d-flex align-items-center rounded-3 border-1 border-light-subtle"
              }
              disabled={isUploading || isShowingLoadingOverlay}
            >
              <PlusLg className={"me-2"} />
              {isScreenLg ? "Meer documenten selecteren" : "Meer selecteren"}
            </Button>
          </div>

          <Button
            title={"Documenten uploaden"}
            variant="dark"
            type="button"
            disabled={
              rowData.length === 0 || isUploading || isShowingLoadingOverlay
            }
            onClick={handleUpload}
            className={"d-flex align-items-center rounded-3"}
          >
            <CloudArrowUp className={"me-2"} />
            {isScreenLg ? "Documenten uploaden" : "Uploaden"}
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
