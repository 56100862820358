import React, { FormEvent, useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import AuthCardFooter from "../../common/AuthCardFooter";
import { Auth, isAuthenticatedIndication } from "../../common/api/Auth";
import AuthCardHeader from "../../common/AuthCardHeader";
import { useAppSelector } from "../../../hooks";

export default function Login(): React.JSX.Element {
  const navigate = useNavigate();

  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);

  const [formDataEmail, setFormDataEmail] = React.useState<string>("");
  const [formDataPassword, setFormDataPassword] = React.useState<string>("");
  const [apiError, setApiError] = React.useState<IApiError | undefined>();

  useEffect(() => {
    isAuthenticatedIndication().then((isAuthenticated) => {
      if (isAuthenticated) {
        // Redirect to the root/chats page, if already authenticated.
        navigate("/dashboard/chats");
      } else if (!["/login", "/signup"].includes(window.location.pathname)) {
        // Redirect to the login page, if not already there.
        navigate("/login");
      }
    });
  }, [navigate]);

  const submitForm = async (e: FormEvent): Promise<void> => {
    /**
     * Login a user via email/password.
     */
    e.preventDefault();
    // Clear previous errors, if any.
    setApiError(undefined);
    // Prepare form data.
    const formData = {
      email: formDataEmail,
      password: formDataPassword,
    };
    // Login the user.
    await Auth.auth(formData, "login").then(
      () => {
        navigate("/dashboard/chats");
      },
      (error: IApiError) => {
        setApiError(error);
      },
    );
  };

  return (
    <div className={"w-100 d-flex justify-content-center align-items-center"}>
      <Card
        className={"rounded-3 border-1 border-light-subtle bg-light m-3"}
        style={{ width: "100%", maxWidth: "25rem" }}
      >
        <Card.Header className={"rounded-top-3 border-0 bg-light"}>
          <AuthCardHeader title={"Inloggen"} isScreenLg={isScreenLg} />
        </Card.Header>

        <Form onSubmit={submitForm}>
          <Card.Body className={"bg-light px-4 pb-4"}>
            <Form.Group className="mb-3">
              <Form.Label column={false} className={"small text-muted mb-1"}>
                Email
              </Form.Label>
              <Form.Control
                name="email"
                type="email"
                placeholder="Email"
                value={formDataEmail}
                onChange={(e) => setFormDataEmail(e.target.value)}
                required={true}
                className={
                  "rounded-pill py-2 px-3 border-2 border-light-subtle"
                }
              />
              {apiError?.errorData?.errors?.map(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (errorMessage: { [key: string]: any }, _index: number) => {
                  if (errorMessage.param === "email") {
                    return (
                      <Form.Text
                        key={`error-${errorMessage.param}-${_index}`}
                        className="text-danger"
                      >
                        {errorMessage.message}
                        <br />
                      </Form.Text>
                    );
                  }
                },
              )}
            </Form.Group>

            <Form.Group className="mb-2" controlId="formPassword">
              <Form.Label column={false} className={"small text-muted mb-1"}>
                Wachtwoord
              </Form.Label>
              <Form.Control
                name="password"
                type="password"
                placeholder="Wachtwoord"
                value={formDataPassword}
                onChange={(e) => setFormDataPassword(e.target.value)}
                required={true}
                className={
                  "rounded-pill py-2 px-3 border-2 border-light-subtle"
                }
              />
              {apiError?.errorData?.errors?.map(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (errorMessage: { [key: string]: any }, _index: number) => {
                  if (errorMessage.param === "password") {
                    return (
                      <Form.Text
                        key={`error-${errorMessage.param}-${_index}`}
                        className="text-danger"
                      >
                        {errorMessage.message}
                        <br />
                      </Form.Text>
                    );
                  }
                },
              )}
            </Form.Group>

            <div className={"d-flex justify-content-end"}>
              <a
                href={"#"}
                onClick={() =>
                  (window.location.href = "/password-reset/request")
                }
                className="text-secondary link-underline-secondary"
              >
                <Form.Text>Wachtwoord vergeten?</Form.Text>
              </a>
            </div>

            <div className={"d-flex justify-content-end mt-4"}>
              <Button
                title={"Inloggen"}
                variant="dark"
                type="submit"
                className={"rounded-3"}
              >
                Inloggen
              </Button>
            </div>
          </Card.Body>

          <Card.Footer
            className={
              "rounded-bottom-3 d-flex align-items-center justify-content-between text-muted border-0 py-3"
            }
          >
            <AuthCardFooter />
          </Card.Footer>
        </Form>
      </Card>
    </div>
  );
}
