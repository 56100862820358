import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RecentChatsState {
  value: IChatRowData[];
}

const defaultState: RecentChatsState = {
  value: [],
};

export const recentChatsSlice = createSlice({
  name: "recentChats",
  initialState: defaultState,
  reducers: {
    // Add a chat to the recent chats list. We keep at most 5 recent chats in the list.
    // We prepend the new chat to the list, instead of append.
    // If the list is full, we remove the oldest chat.
    addChat: (state, action: PayloadAction<IChatRowData>) => {
      if (state.value.length >= 5) {
        state.value = [action.payload, ...state.value.slice(0, -1)];
      } else {
        state.value = [action.payload, ...state.value];
      }
    },

    setChats: (state, action: PayloadAction<IChatRowData[]>) => {
      state.value = action.payload;
    },

    modifyChatTopic: (state, action) => {
      // Steps:
      // 1. Find the index chat in the list.
      // 2. Create a new chat item with the new topic.
      // 3. Replace the chat item in the list with the new chat item.
      const chatId = state.value.findIndex(
        (chat) => chat.id === action.payload.id,
      );
      state.value[chatId] = {
        topic: action.payload.topic,
        id: action.payload.id,
        created: state.value[chatId].created,
        modified: state.value[chatId].modified,
      };
    },
  },
});

export const { addChat, modifyChatTopic, setChats } = recentChatsSlice.actions;
export default recentChatsSlice.reducer;
