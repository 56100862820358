import React, { FormEvent, useEffect, useRef } from "react";
import {
  Button,
  Card,
  Form,
  InputGroup,
  Overlay,
  OverlayTrigger,
  Popover,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import {
  ArrowLeft,
  HandThumbsDown,
  HandThumbsDownFill,
  HandThumbsUp,
  HandThumbsUpFill,
  Send,
  Copy,
  VolumeUpFill,
  StopCircleFill,
} from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router-dom";
import parse from "html-react-parser";

import { Api } from "../../common/api/Api";
import { dateTimeToStr, truncateString } from "../../common/Utils";
import avatar from "../../../assets/images/avatar192.png";
import ErrorAlert from "../../common/ErrorAlert";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { modifyChatTopic, addChat } from "../../../features/recentChatsSlice";
import { getOrganizationFromStorage } from "../../common/api/Utils";
import { NoDocuments } from "../../common/NoDocuments";

export default function ChatsDetail(): React.JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputFieldRef: any = useRef();
  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);

  const maxMessageLength: number = 1000;
  const [message, setMessage] = React.useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [chatData, setChatData] = React.useState<IChat | any>({});
  const chatMetadata: IChatRowData = location?.state?.chat;
  const [chatTopic, setChatTopic] = React.useState<string>(
    chatMetadata?.topic ? chatMetadata.topic : "",
  );
  const [messagesData, setMessagesData] = React.useState<
    Array<IQuestion | IAnswer>
  >([]);
  const [organizationData, setOrganizationData] = React.useState<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    IOrganization | any
  >({});
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isAnswering, setIsAnswering] = React.useState<boolean>(false);
  const [apiError, setApiError] = React.useState<IApiError | undefined>();
  const [haveDocuments, setHaveDocuments] = React.useState<boolean>(false);

  // This is like a lock...
  const [playingMessage, setPlayingMessage] = React.useState<IAnswer | null>(
    null,
  );
  // Keep a ref around because changes to this thing should not cause a re-render.
  const audioElementRef = useRef<HTMLAudioElement | null>(null);
  const [isAudioLoading, setAudioIsLoading] = React.useState<boolean>(false);

  const defaultError: string = "Sorry, op deze vraag heb ik geen antwoord.";
  const feedbackThumbsUp: string = "thumbs_up";
  const feedbackThumbsDown: string = "thumbs_down";

  // Method to reset state in case user navigates to a new chat.
  const resetState = () => {
    // Reset
    setChatData({});
    setMessagesData([]);
    setChatTopic(chatMetadata?.topic ? chatMetadata.topic : "");
    setApiError(undefined);
    setMessage("");
    setIsAnswering(false);
  };

  // Set ChatData whenever the chatMetadata changes.
  // Here we want to reset the chat data whenever the chatMetadata changes,
  // And not just append to things.
  useEffect(() => {
    setIsLoading(true);
    if (!chatMetadata?.id) {
      // If no chat ID, redirect to chats overview
      // (this is probably caused by a user copy-pasting the URL).
      return navigate("/dashboard/chats");
    }

    resetState();

    // Check if the organization has documents
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Api.get("documents/any/").then((response: { [key: string]: any }) => {
      if (response.exists && response.exists === true) {
        setHaveDocuments(true);
        // Load the chat data, on first load, only if we have documents
        getOrganizationFromStorage().then(
          (organization: IOrganization | null) => {
            setOrganizationData(organization);
            getChatData().then(() => {
              setTimeout(() => {
                setIsLoading(false);
                setTimeout(() => {
                  scrollToBottomOfChatWindow();
                }, 1);
              }, 0);
            });
          },
        );
      } else {
        // No documents, we don't need to get chat data anymore
        setHaveDocuments(false);
        setIsLoading(false);
      }
    });
  }, [navigate, chatMetadata?.id]);

  useEffect(() => {
    // Focus on the input field, when chat has been loaded and answer has been generated.
    if (!isLoading && !isAnswering && !apiError && haveDocuments) {
      // Focus on the input field.
      inputFieldRef.current.focus();
    }
  }, [isLoading, isAnswering, apiError, haveDocuments]);

  const scrollToBottomOfChatWindow = () => {
    // Scroll to bottom of chat window.
    const chatWindow: HTMLElement | null =
      document.getElementById("chatWindow");
    if (chatWindow) {
      chatWindow.scrollTo(0, chatWindow.scrollHeight);
    }
  };

  const handleCitationFileView = (
    citation: ICitation,
    citationIndex: number,
  ): void => {
    /**
     * View the citation's document's file from the answer data.
     */
    navigate("/dashboard/chats/chat/citation", {
      state: {
        citation: citation,
        citationIndex: citationIndex,
        chatTopic: chatMetadata?.topic,
      },
    });
  };

  const handleCopyAnswer = (answer: IAnswer): void => {
    if (!navigator.clipboard && !!document.execCommand) {
      // Clipboard API may not be available. In that case we create a textarea and copy the answer to clipboard.
      // Note that execCommand is deprecated and may be removed in the future.
      // In that case we simply do nothing...
      const textArea = document.createElement("textarea");
      textArea.value = answer.message;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("Copy");
      document.body.removeChild(textArea);
    } else if (navigator.clipboard) {
      navigator.clipboard.writeText(answer.message).then(
        () => {
          // TODO (Sander): Maybe show a success popover?
        },
        () => {
          // TODO (Rob): Show error message?
        },
      );
    } else {
      // TODO (Sander): Show error message?
    }
  };

  const handleFeedback = (answer: IAnswer, feedback: string): void => {
    /**
     * Store given feedback.
     */
    // Update the answer message.
    answer.feedback = feedback;
    // Prepare the form data.
    const data: FormData = new FormData();
    data.append("feedback", feedback);
    // Make the request.
    Api.patch(`answers/${answer.id}/`, data).then(
      () => {
        // Pass.
      },
      (error: IApiError) => {
        setApiError(error);
      },
    );
  };

  const handleChatNew = (): void => {
    /**
     * Start a new chat.
     */
    // Show loading indicator.
    setIsLoading(true);
    // Create a new chat.
    Api.post("chats/", {}).then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: { [key: string]: any }) => {
        const newChatRowData: IChatRowData = {
          id: response.id,
          topic: "",
          created: dateTimeToStr(new Date(response.created), "US"),
          modified: dateTimeToStr(new Date(response.modified), "US"),
        };
        // View the newly created chat.
        dispatch(addChat(newChatRowData));
        navigate("/dashboard/chats/chat", { state: { chat: newChatRowData } });
      },
      (error: IApiError) => {
        setApiError(error);
        setIsLoading(false);
      },
    );
  };

  const getChatData = async () => {
    await Api.get(`chats/${chatMetadata?.id}/`).then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: { [key: string]: any }) => {
        // Get the chat data.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const _chatData: { [key: string]: any } = response;
        // Save the chat data.
        setChatData(_chatData);
        // Get the question(s) for the chat, if any.
        _chatData.questions.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          async (_questionData: { [key: string]: any }) => {
            const _questionMessageData: IQuestion = {
              type: "question",
              url: _questionData.url,
              message: _questionData.message,
              created: new Date(_questionData.created),
            };
            setMessagesData((_messagesData) => [
              ..._messagesData,
              _questionMessageData,
            ]);
            // Get the answer for the question, if any.
            if (_questionData.answer) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const _answerData: { [key: string]: any } = _questionData.answer;
              // Get the citations for the answer, if any.
              const _citationsData: Array<ICitation> = [];
              _answerData.citations.map(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                async (_citationData: { [key: string]: any }) => {
                  _citationsData.push({
                    document: {
                      url: _citationData.document_chunk.document
                        .file_download_url,
                      extension:
                        _citationData.document_chunk.document.extension,
                      name: `${_citationData.document_chunk.document.title}.${_citationData.document_chunk.document.extension}`,
                    },
                    pageStart: _citationData.document_chunk.page_number_start,
                    pageEnd: _citationData.document_chunk.page_number_end,
                  });
                },
              );
              // Add the answer message.
              const _answerMessageData: IAnswer = {
                type: "answer",
                id: _answerData.id,
                url: _answerData.url,
                message: _answerData.message,
                created: new Date(_answerData.created),
                citations: _citationsData,
                feedback: _answerData.feedback,
              };
              setMessagesData((_messagesData) => [
                ..._messagesData,
                _answerMessageData,
              ]);
            } else {
              const _answerMessageErrorData: IAnswer = {
                type: "answer",
                id: null,
                url: "",
                message: defaultError,
                created: null,
                citations: [],
                feedback: "",
              };
              setMessagesData((_messagesData) => [
                ..._messagesData,
                _answerMessageErrorData,
              ]);
            }
          },
        );
      },
      (error: IApiError) => {
        setApiError(error);
      },
    );
  };

  const getAssistantGreeting = (): string => {
    if (organizationData.assistantGreeting) {
      return organizationData.assistantGreeting;
    } else if (organizationData.assistantName) {
      return `Ik ben ${organizationData.assistantName}, jouw persoonlijke AI assistent. Waar kan ik mee helpen?`;
    } else {
      return "Waar kan ik mee helpen?";
    }
  };

  const handleMessageSend = async (e: FormEvent): Promise<void> => {
    /**
     * Handle sending a message to Manual.
     */
    e.preventDefault();
    setIsAnswering(true);
    await postMessage(message)
      .then(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (responseQuestion: { [key: string]: any } | any) => {
          // Remove the placeholder answer message.
          setMessagesData((_messagesData) => _messagesData.slice(0, -1));
          // Add the answer message.
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const _answerData: { [key: string]: any } = responseQuestion.answer;
          if (_answerData) {
            // Get the citations for the answer, if any.
            const _citationsData: Array<ICitation> = [];
            _answerData.citations.map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              async (_citationData: { [key: string]: any }) => {
                _citationsData.push({
                  document: {
                    url: _citationData.document_chunk.document
                      .file_download_url,
                    extension: _citationData.document_chunk.document.extension,
                    name: `${_citationData.document_chunk.document.title}.${_citationData.document_chunk.document.extension}`,
                  },
                  pageStart: _citationData.document_chunk.page_number_start,
                  pageEnd: _citationData.document_chunk.page_number_end,
                });
              },
            );
            // Add the answer message.
            const _answerMessageData: IAnswer = {
              type: "answer",
              id: _answerData.id,
              url: _answerData.url,
              message: _answerData.message,
              created: new Date(_answerData.created),
              citations: _citationsData,
              feedback: _answerData.feedback,
            };
            setMessagesData((_messagesData) => [
              ..._messagesData,
              _answerMessageData,
            ]);
          } else {
            const _answerMessageErrorData: IAnswer = {
              type: "answer",
              id: null,
              url: "",
              message: defaultError,
              created: null,
              citations: [],
              feedback: "",
            };
            setMessagesData((_messagesData) => [
              ..._messagesData,
              _answerMessageErrorData,
            ]);
          }
        },
        () => {
          // Show error message.
          // Remove the placeholder answer message.
          setMessagesData((_messagesData) => _messagesData.slice(0, -1));
          // Add the error answer message.
          const _answerMessageErrorData: IAnswer = {
            type: "answer",
            id: null,
            url: "",
            message: defaultError,
            created: null,
            citations: [],
            feedback: "",
          };
          setMessagesData((_messagesData) => [
            ..._messagesData,
            _answerMessageErrorData,
          ]);
        },
      )
      .finally(() => {
        setIsAnswering(false);
        setTimeout(() => {
          scrollToBottomOfChatWindow();
        }, 1);
      });
  };

  const postMessage = async (
    _message: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<{ [key: string]: any } | IApiError> => {
    /**
     * Post a message to Manual server.
     */
    // Clear message input field.
    setMessage("");
    // Add the question message.
    const _questionMessageData: IQuestion = {
      type: "question",
      url: "",
      message: _message,
      created: new Date(),
    };
    setMessagesData((_messagesData) => [
      ..._messagesData,
      _questionMessageData,
    ]);
    // Add the answer message (placeholder).
    const _answerMessagePlaceholderData: IAnswer = {
      type: "answer",
      id: null,
      url: "",
      message: "",
      created: null,
      citations: [],
      feedback: "",
    };
    setMessagesData((_messagesData) => [
      ..._messagesData,
      _answerMessagePlaceholderData,
    ]);
    setTimeout(() => {
      scrollToBottomOfChatWindow();
    }, 1);
    // Prepare the form data.
    const data: FormData = new FormData();
    data.append("message", _message);
    data.append("chat", chatData.url);
    // Make the request.
    return Api.post("questions/", data).then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: { [key: string]: any }) => {
        // If we got a new topic, we can update the chat topic.
        if (chatMetadata.topic !== response.chat_topic) {
          // Modify in the redux store so other components can get the new topic.
          // But also set our own topic
          dispatch(
            modifyChatTopic({
              id: chatMetadata.id,
              topic: response.chat_topic,
            }),
          );
          setChatTopic(response.chat_topic);
        }
        return response;
      },
      (error: IApiError) => {
        setApiError(error);
        return error;
      },
    );
  };

  const QuestionComponent = (props: { message: IQuestion }) => {
    const question: IQuestion = props.message;
    return (
      <div className={"d-flex justify-content-end mb-3"}>
        <Card
          className={"w-75 bg-light rounded-3 border-1 border-light-subtle"}
        >
          <Card.Body>
            <p className={"m-0"}>
              {parse(question.message.replace(/\n/g, "<br/>"))}
            </p>
          </Card.Body>
          {question.created ? (
            <Card.Footer
              className="d-flex align-items-center justify-content-between text-muted border-0 rounded-bottom-3"
              style={{ minHeight: "3rem" }}
            >
              <small>{dateTimeToStr(question.created)}</small>
            </Card.Footer>
          ) : (
            <></>
          )}
        </Card>
      </div>
    );
  };

  const CopyComponent = (props: { answer: IAnswer }) => {
    const [show, setShow] = React.useState<boolean>(false);
    return (
      <OverlayTrigger
        show={show}
        trigger={"click"}
        placement={"left"}
        overlay={
          <Tooltip id={"tooltip-copy-answer"} className={"ms-3"}>
            <div className={"py-1"}>
              Antwoord is gekopiëerd naar het klembord.
            </div>
          </Tooltip>
        }
      >
        <Button
          title={"Kopieer antwoord"}
          size={"sm"}
          variant="light"
          type="button"
          onClick={() => {
            handleCopyAnswer(props.answer);
            setShow(true);
            setTimeout(() => {
              setShow(false);
            }, 1000);
          }}
          className={
            "rounded-circle py-0 text-dark me-4 border-1 border-light-subtle"
          }
        >
          {" "}
          <span className={"d-flex align-items-center py-2"}>
            <Copy />
          </span>
        </Button>
      </OverlayTrigger>
    );
  };

  const _isApiError = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj: IApiError | { [key: string]: any },
  ): obj is IApiError => {
    return (obj as IApiError).errorMessage !== undefined;
  };

  const AnswerSpeechComponent = (props: { message: IAnswer }) => {
    const handlePlay = async () => {
      setAudioIsLoading(true);
      setPlayingMessage(props.message);

      const speechItem = await Api.get(`answers/${props.message.id}/speech/`);
      if (_isApiError(speechItem)) {
        setApiError(speechItem);
        return;
      }
      const base64 = speechItem.speech.data;
      const binaryString = atob(base64);
      const bytes = new Uint8Array(binaryString.length);

      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const audioBlob = new Blob([bytes.buffer], {
        type: `audio/${speechItem.speech.encoding}`,
      });
      const audioUrl = URL.createObjectURL(audioBlob);

      try {
        // In a try because media stream may be corrupted and whatnot
        audioElementRef.current = new Audio(audioUrl);

        // Set the onended event to reset the playing state
        audioElementRef.current.onended = () => {
          setPlayingMessage(null);
        };

        await audioElementRef.current.play();
      } catch (e) {
        console.log(audioUrl);
        console.log(audioBlob);

        console.error(e);
      }
      setAudioIsLoading(false);
    };

    const handleStop = () => {
      console.log("audio element is... {}", audioElementRef.current);
      audioElementRef.current?.pause();
      setPlayingMessage(null);
    };

    const getIcon = () => {
      const currentMessageIsOwn = playingMessage?.id === props.message.id;
      if (!currentMessageIsOwn) {
        return <VolumeUpFill />;
      }

      if (isAudioLoading) {
        return (
          <Spinner
            size={"sm"}
            style={{ maxWidth: "14px", maxHeight: "14px" }}
          />
        );
      }
      return <StopCircleFill />;
    };

    return (
      <Button
        title={playingMessage?.id === props.message.id ? "Stop" : "Speel af"}
        size={"sm"}
        variant={"light"}
        type={"button"}
        className={
          "rounded-circle py-0 text-dark me-2 border-1 border-light-subtle"
        }
        onClick={
          playingMessage?.id === props.message.id ? handleStop : handlePlay
        }
        disabled={
          isAudioLoading ||
          (playingMessage !== null && playingMessage.id !== props.message.id)
        }
      >
        <span className={"d-flex align-items-center py-2"}>{getIcon()}</span>
      </Button>
    );
  };

  const AnswerComponent = (props: { message: IAnswer }) => {
    const answer: IAnswer = props.message;
    const [_feedback, _setFeedback] = React.useState<string>(answer.feedback);
    const _handleFeedback = (feedback: string): void => {
      if (_feedback === feedback) {
        // Reset the feedback.
        feedback = "";
      }
      // Update the feedback locally.
      answer.feedback = feedback;
      _setFeedback(feedback);
      // Store the feedback.
      handleFeedback(answer, feedback);
    };
    const [showAssistantOverlay, setShowAssistantOverlay] =
      React.useState(false);
    const assistantOverlayRef = useRef(null);

    return (
      <div className={"d-flex justify-content-start mb-3"}>
        {isScreenLg ? (
          <>
            <img
              alt="Avatar"
              title={"Avatar"}
              src={
                organizationData && organizationData.avatarUrl
                  ? organizationData.avatarUrl
                  : avatar
              }
              height="38"
              width="38"
              className={
                "overflow-hidden rounded-circle border border-1 border-light-subtle bg-transparent me-2"
              }
              style={{
                height: "38px",
                width: "38px",
                aspectRatio: 1,
                cursor: organizationData.avatarUrl ? "pointer" : "auto",
              }}
              ref={assistantOverlayRef}
              onClick={() => {
                if (organizationData.avatarUrl) {
                  setShowAssistantOverlay(!showAssistantOverlay);
                }
              }}
            />
            <Overlay
              target={assistantOverlayRef.current}
              show={showAssistantOverlay}
              placement="right"
            >
              <Popover className={"rounded-3 border-light-subtle"}>
                <Popover.Body
                  className={"d-flex align-content-center align-items-center"}
                >
                  <div className={"flex-fill"}>
                    <img
                      alt="Avatar"
                      title={"Avatar"}
                      src={
                        organizationData && organizationData.avatarUrl
                          ? organizationData.avatarUrl
                          : avatar
                      }
                      height="96"
                      width="96"
                      className={
                        "overflow-hidden rounded-circle border border-2 border-light-subtle bg-transparent me-3"
                      }
                      style={{ height: "96px", width: "96px", aspectRatio: 1 }}
                    />
                  </div>
                  <span>{getAssistantGreeting()}</span>
                </Popover.Body>
              </Popover>
            </Overlay>
          </>
        ) : (
          <></>
        )}
        <Card
          className={"w-75 bg-light rounded-3 border-1 border-light-subtle"}
        >
          <Card.Body>
            {props.message.message === "" ? (
              <div
                className={
                  "h-100 d-flex justify-content-center align-items-center"
                }
              >
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <div>
                <p className={"m-0"}>
                  {parse(answer.message.replace(/\n/g, "<br/>"))}
                </p>
                {answer.citations && answer.citations.length > 0 ? (
                  <>
                    <hr />
                    <small>
                      Citaties:
                      <ol className={"mb-0 overflow-hidden"}>
                        {answer.citations.map(
                          (citationData: ICitation, index: number) => {
                            const citationIndex: number = index + 1;
                            return (
                              <li
                                key={`answer-${props.message.id}-citation-${index}`}
                              >
                                <span
                                  className={
                                    "m-0 text-decoration-underline text-truncate"
                                  }
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleCitationFileView(
                                      citationData,
                                      citationIndex,
                                    )
                                  }
                                >
                                  {citationData.document.name}
                                  {citationData.pageStart &&
                                  citationData.pageEnd ? (
                                    <>
                                      {" "}
                                      {citationData.pageStart ===
                                      citationData.pageEnd ? (
                                        <>(pagina {citationData.pageStart})</>
                                      ) : (
                                        <>
                                          (pagina&apos;s{" "}
                                          {citationData.pageStart}-
                                          {citationData.pageEnd})
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </li>
                            );
                          },
                        )}
                      </ol>
                    </small>
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}
          </Card.Body>
          {answer.created ? (
            <Card.Footer
              className="d-flex flex-wrap align-items-center justify-content-between text-muted border-0 rounded-bottom-3"
              style={{ minHeight: "3rem" }}
            >
              {isScreenLg ? (
                <small>{dateTimeToStr(answer.created)}</small>
              ) : (
                <></>
              )}
              <div
                className={
                  isScreenLg
                    ? "d-flex justify-content-center"
                    : "w-100 d-flex justify-content-end"
                }
              >
                <div>
                  <AnswerSpeechComponent message={answer} />
                  <CopyComponent answer={answer} />
                  <Button
                    title={"Juist antwoord"}
                    size={"sm"}
                    variant="light"
                    type="button"
                    active={_feedback === feedbackThumbsUp}
                    onClick={() => _handleFeedback(feedbackThumbsUp)}
                    className={
                      "rounded-circle py-0 text-dark me-2 border-1 border-light-subtle"
                    }
                  >
                    <span className={"d-flex align-items-center py-2"}>
                      {_feedback === feedbackThumbsUp ? (
                        <HandThumbsUpFill />
                      ) : (
                        <HandThumbsUp />
                      )}
                    </span>
                  </Button>
                  <Button
                    title={"Onjuist antwoord"}
                    size={"sm"}
                    variant="light"
                    type="button"
                    active={_feedback === feedbackThumbsDown}
                    onClick={() => _handleFeedback(feedbackThumbsDown)}
                    className={
                      "rounded-circle py-0 text-dark border-1 border-light-subtle"
                    }
                  >
                    <span className={"d-flex align-items-center py-2"}>
                      {_feedback === feedbackThumbsDown ? (
                        <HandThumbsDownFill />
                      ) : (
                        <HandThumbsDown />
                      )}
                    </span>
                  </Button>
                </div>
              </div>
            </Card.Footer>
          ) : (
            <></>
          )}
        </Card>
      </div>
    );
  };

  return (
    <div className={"h-100 d-flex flex-column"}>
      <div className={"d-flex align-items-center mb-3 py-1 text-muted"}>
        <Button
          title={"Vorige"}
          name={"Vorige"}
          type="button"
          onClick={() => navigate("/dashboard/chats")}
          className={"d-flex me-2 border-0 bg-transparent text-muted p-0"}
        >
          <ArrowLeft />
        </Button>
        <h6
          onClick={() => navigate("/dashboard/chats")}
          className={"m-0 ms-1 text-decoration-underline"}
          style={{ cursor: "pointer" }}
        >
          Chats
        </h6>
        <h6 className={"m-0 mx-2"}>/</h6>
        <h6 className={"m-0 text-truncate"}>
          Chat
          {chatTopic !== "" ? (
            <span>
              : {` `}
              <i>{truncateString(chatTopic)}</i>
            </span>
          ) : (
            ""
          )}
        </h6>
      </div>

      <div
        className="h-100 rounded-3 d-flex flex-column justify-content-between"
        style={{ minHeight: "15rem" }}
      >
        {isLoading ? (
          <div
            className={"h-100 d-flex justify-content-center align-items-center"}
          >
            <Spinner animation="border" variant="secondary" />
          </div>
        ) : apiError ? (
          <ErrorAlert apiError={apiError} />
        ) : !haveDocuments && !isLoading ? (
          <>
            <NoDocuments />
          </>
        ) : (
          <>
            <div
              id={"chatWindow"}
              className={"flex-grow-1 d-flex flex-column overflow-auto"}
              style={{
                height: 0,
                marginRight: "-0.75rem",
                paddingRight: "0.75rem",
              }}
            >
              <AnswerComponent
                message={{
                  message: getAssistantGreeting(),
                  created: null,
                  citations: [],
                  feedback: "",
                  id: null,
                  type: "answer",
                  url: "",
                }}
              />
              {messagesData.map(
                (messageData: IQuestion | IAnswer, index: number) => {
                  if (messageData.type === "question") {
                    return (
                      <QuestionComponent key={index} message={messageData} />
                    );
                  } else if (messageData.type === "answer") {
                    return (
                      <AnswerComponent key={index} message={messageData} />
                    );
                  }
                  return null;
                },
              )}
            </div>

            <Form className={"mt-3"} onSubmit={handleMessageSend}>
              <InputGroup
                className={"rounded-5 border border-1 border-light-subtle"}
              >
                <Form.Control
                  type={"text"}
                  as={"textarea"}
                  // Allow minimum 1 and maximum 10 rows.
                  rows={Math.min(
                    Math.max(message.split(/\r\n|\r|\n/).length, 1),
                    10,
                  )}
                  onKeyDown={(e) => {
                    // Submit the form on Enter key press
                    // (unless Shift key is also pressed).
                    if (e.key.toLowerCase() === "enter" && !e.shiftKey) {
                      e.currentTarget.form?.requestSubmit();
                    }
                  }}
                  placeholder={
                    messagesData.length > 0
                      ? "Stel hier je vervolgvraag..."
                      : "Stel hier je vraag..."
                  }
                  aria-label={
                    messagesData.length > 0
                      ? "Stel hier je vervolgvraag..."
                      : "Stel hier je vraag..."
                  }
                  required={true}
                  value={message}
                  autoFocus={true}
                  disabled={isLoading || isAnswering}
                  onChange={(e) => setMessage(e.target.value)}
                  className={"rounded-5 bg-light border-5 border-light z-2"}
                  style={{ paddingRight: "7rem", maxHeight: "18rem" }}
                  ref={inputFieldRef}
                  // Limit the input to 1000 characters
                  // (which should be well withing the LLM's limit of 8192 tokens
                  // and sufficient for asking a complete question).
                  maxLength={maxMessageLength}
                />
                <Button
                  title={"Verstuur"}
                  variant="dark"
                  type="submit"
                  disabled={
                    isLoading ||
                    isAnswering ||
                    message.length === 0 ||
                    message.length > maxMessageLength
                  }
                  className={
                    "d-flex align-items-center position-absolute end-0 bottom-0 rounded-pill border-5 border-light z-3"
                  }
                >
                  <Send className={"me-2"} />
                  Verstuur
                </Button>
              </InputGroup>
              <div className={"text-end small me-3"}>
                <Form.Text className="text-muted">
                  {message.length}/{maxMessageLength}
                </Form.Text>
              </div>
            </Form>

            {messagesData.length > 0 ? (
              <div className={"text-center"} style={{ marginTop: "-0.75rem" }}>
                <span
                  className={
                    "m-0 p-0 text-decoration-underline text-truncate text-muted small"
                  }
                  style={{ cursor: "pointer" }}
                  onClick={isLoading || isAnswering ? undefined : handleChatNew}
                  title={"Begin een nieuwe chat"}
                >
                  Of begin een nieuwe chat
                </span>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
}
