export const dateTimeToStr = (
  dateTime: Date,
  format: string = "NL",
): string => {
  /**
   * Format a datetime value to a datetime string.
   * Supported format are "US" (for sorting) and "NL" (for display).
   */
  const day: string = String(dateTime.getDate()).padStart(2, "0");
  const month: string = String(dateTime.getMonth() + 1).padStart(2, "0");
  const year: string = String(dateTime.getFullYear());
  const hours: string = String(dateTime.getHours()).padStart(2, "0");
  const minutes: string = String(dateTime.getMinutes()).padStart(2, "0");
  let dateTimeStr: string = "";
  if (format === "NL") {
    dateTimeStr = `${day}-${month}-${year} om ${hours}:${minutes}`;
  } else if (format === "US") {
    dateTimeStr = `${year}-${month}-${day}@${hours}:${minutes}`;
  }
  return dateTimeStr;
};

export const strToDateTime = (
  dateTimeStr: string,
  format: string = "US",
): Date => {
  /**
   * Format a datetime string to a datetime value.
   * Supported format are "US" (for sorting) and "NL" (for display).
   */
  const dateTimeParts: string[] = dateTimeStr.split("@");
  const dateParts: string[] = dateTimeParts[0].split("-");
  const timeParts: string[] = dateTimeParts[1].split(":");
  let dateTime: Date = new Date();
  if (format === "NL") {
    dateTime = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0]),
      Number(timeParts[0]),
      Number(timeParts[1]),
    );
  } else if (format === "US") {
    dateTime = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
      Number(dateParts[2]),
      Number(timeParts[0]),
      Number(timeParts[1]),
    );
  }
  return dateTime;
};

export const truncateString = (
  fullStr: string,
  truncateAfterCharCount: number = 50,
): string => {
  if (fullStr.length <= truncateAfterCharCount) {
    return fullStr;
  } else {
    return fullStr.substring(0, truncateAfterCharCount) + "\u2026";
  }
};
