import React from "react";
import { Spinner } from "react-bootstrap";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";

import "./App.css";
import Dashboard from "./components/screens/Dashboard";
import Login from "./components/screens/auth/Login";
import Signup from "./components/screens/auth/Signup";
import { isAuthenticatedIndication } from "./components/common/api/Auth";
import PasswordResetRequest from "./components/screens/auth/PasswordResetRequest";
import PasswordResetSetPassword from "./components/screens/auth/PasswordResetSetPassword";
import { useAppDispatch } from "./hooks";
import { updateIsScreenLg } from "./features/isScreenLgSlice";

export default function App(): React.JSX.Element {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(
    null,
  );

  React.useEffect(() => {
    /**
     * On screen size change, update the screen size state.
     */
    window.addEventListener("resize", () => {
      dispatch(updateIsScreenLg());
    });
  }, []);

  React.useEffect(() => {
    /**
     * On every page/screen change, check if the user is authenticated.
     */
    isAuthenticatedIndication().then((_isAuthenticated) => {
      setIsAuthenticated(_isAuthenticated);
    });
  }, [location.pathname]);

  return (
    <div className={"App d-flex flex-column mh-100vh bg-white text-dark"}>
      <div className={"d-flex flex-fill"}>
        {isAuthenticated === null ? (
          <Spinner animation="border" variant="secondary" />
        ) : (
          <Routes>
            {isAuthenticated ? (
              <>
                <Route path="*" element={<Navigate to="dashboard" />} />
                <Route path="dashboard/*" element={<Dashboard />} />
              </>
            ) : (
              <>
                <Route path="*" element={<Navigate to="login" />} />
                <Route path="login" element={<Login />} />
                <Route path="signup" element={<Signup />} />
                <Route
                  path="password-reset/request"
                  element={<PasswordResetRequest />}
                />
                <Route
                  path="password-reset/reset"
                  element={<PasswordResetSetPassword />}
                />
              </>
            )}
          </Routes>
        )}
      </div>
    </div>
  );
}
