import { AxiosError, AxiosResponse } from "axios";
import {
  apiErrorGeneric,
  deleteOrganizationFromStorage,
  deleteUserFromStorage,
  getAxiosInstance,
  getUserFromStorage,
  handleAxiosError,
  setOrganizationInStorage,
  setUserInStorage,
} from "./Utils";

export class Auth {
  static async auth(
    /**
     * Auth related actions (login, signup, password reset request and password reset),
     * following Allauth's headless API:
     * - Login & signup: https://docs.allauth.org/en/latest/headless/openapi-specification/#tag/Authentication:-Account
     * - Password reset (request & reset): https://docs.allauth.org/en/latest/headless/openapi-specification/#tag/Authentication:-Password-Reset
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    action: "login" | "signup" | "password/request" | "password/reset",
  ): Promise<object | IApiError> {
    const axiosInstance = await getAxiosInstance(false, true).then(
      (_axiosInstance) => {
        return _axiosInstance;
      },
    );
    const path: string = `accounts/browser/v1/auth/${action}`;
    return await axiosInstance.post(path, data).then(
      async (response: AxiosResponse) => {
        if ([200].includes(response.status)) {
          // Store user and organization in localStorage, if in response.
          if (response.data?.data?.user) {
            const organization: IOrganization = {
              id: response.data.data.user.organization.id,
              name: response.data.data.user.organization.name,
              logoUrl: response.data.data.user.organization.logo,
              avatarUrl: response.data.data.user.organization.avatar,
              assistantName:
                response.data.data.user.organization.assistant_name,
              assistantGreeting:
                response.data.data.user.organization.assistant_greeting,
            };
            await setOrganizationInStorage(organization);
            const user: IUser = {
              id: response.data.data.user.id,
              email: response.data.data.user.email,
              hasAdminRights: response.data.data.user.has_admin_rights,
            };
            await setUserInStorage(user);
          }
          return response.data;
        } else {
          return Promise.reject(apiErrorGeneric);
        }
      },
      async (error: AxiosError) => {
        if (action === "password/reset" && error.response?.status === 401) {
          // We expect to get a 401 here (i.e. an "error"),
          // according to the Allauth headless API spec.
          return error.response?.data;
        } else {
          await handleAxiosError(error);
        }
      },
    );
  }

  static async logout(): Promise<null | void> {
    /**
     * Logout following Allauth's headless API.
     * See https://docs.allauth.org/en/latest/headless/openapi-specification/#tag/Authentication:-Current-Session
     */
    const axiosInstance = await getAxiosInstance(false, true).then(
      (_axiosInstance) => {
        return _axiosInstance;
      },
    );
    return await axiosInstance.delete("accounts/browser/v1/auth/session").then(
      // We expect to get a 401 here (i.e. an "error"),
      // according to the Allauth headless API spec.
      null,
      async (error: AxiosError) => {
        console.assert(error.response?.status === 401);
        return await deleteUserFromStorage().then(async () => {
          await deleteOrganizationFromStorage().then(() => {
            // Redirect to the login page.
            window.location.href = "/login";
          });
        });
      },
    );
  }

  static async syncSession(): Promise<object | IApiError> {
    /**
     * Get the current user's session, following Allauth's headless API.
     * See https://docs.allauth.org/en/latest/headless/openapi-specification/#tag/Authentication:-Current-Session
     * Then sync with localStorage.
     */
    const axiosInstance = await getAxiosInstance(false, true).then(
      (_axiosInstance) => {
        return _axiosInstance;
      },
    );
    return await axiosInstance.get("accounts/browser/v1/auth/session").then(
      async (response: AxiosResponse) => {
        if ([200].includes(response.status)) {
          // Update user and organization in localStorage.
          if (response.data?.data?.user) {
            const organization: IOrganization = {
              id: response.data.data.user.organization.id,
              name: response.data.data.user.organization.name,
              logoUrl: response.data.data.user.organization.logo,
              avatarUrl: response.data.data.user.organization.avatar,
              assistantName:
                response.data.data.user.organization.assistant_name,
              assistantGreeting:
                response.data.data.user.organization.assistant_greeting,
            };
            await deleteOrganizationFromStorage().then(async () => {
              await setOrganizationInStorage(organization);
            });
            const user: IUser = {
              id: response.data.data.user.id,
              email: response.data.data.user.email,
              hasAdminRights: response.data.data.user.has_admin_rights,
            };
            await deleteUserFromStorage().then(async () => {
              await setUserInStorage(user);
            });
          }
          return response.data;
        } else {
          return Promise.reject(apiErrorGeneric);
        }
      },
      async (error: AxiosError) => {
        await handleAxiosError(error);
      },
    );
  }
}

export const isAuthenticatedIndication = async (): Promise<boolean> => {
  // An indication of the user being authenticated, without making an API call
  return await getUserFromStorage().then((_user: IUser | null) => {
    return _user ? Promise.resolve(true) : Promise.resolve(false);
  });
};

export const hasAdminRights = async (): Promise<boolean> => {
  // Check if the user is an admin.
  return await getUserFromStorage().then((_user: IUser | null) => {
    return _user ? _user.hasAdminRights : false;
  });
};
