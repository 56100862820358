import React from "react";
import { Alert } from "react-bootstrap";

export enum AlertVariants {
  Danger = "danger",
}

export default function ErrorAlert(props: {
  apiError?: IApiError;
}): React.JSX.Element {
  // Get the message from input param's API error, if any.
  const message: string =
    props?.apiError?.errorMessage ?? "Oeps, er is iets fout gegaan.";

  return (
    <Alert
      variant={AlertVariants.Danger}
      className={"rounded-3"}
      dismissible={true}
    >
      <p className={"m-0"}>{message}</p>
    </Alert>
  );
}
