import axios, { AxiosError, AxiosInstance } from "axios";

import config from "../../../config";
import { Auth } from "./Auth";
import { Api } from "./Api";

const baseUrl: string = config.backendBaseUrl;
const baseApiUrl: string = `${baseUrl}/api/v1/`;
const baseUserManagementUrl: string = `${baseUrl}/user-management/`;

// TODO (Rob): Do this in Redux instead.
enum LocalStorageKeys {
  user = "user",
  organization = "organization",
}

// This is Django's default CSRF cookie name
// (see https://docs.djangoproject.com/en/5.1/ref/settings/#std-setting-CSRF_COOKIE_NAME).
const csrfCookieName: string = "csrftoken";
// This is Django's default CSRF header name
// (see https://docs.djangoproject.com/en/5.1/ref/settings/#std-setting-CSRF_HEADER_NAME).
const csrfHeaderName: string = "X-CSRFTOKEN";

enum ApiErrorMessages {
  Generic = "Oeps, er is iets fout gegaan.",
  Unauthorized = "De inloggegevens lijken niet te kloppen. Probeer het opnieuw.",
  NoConnection = "Oeps, er lijkt geen verbinding te zijn.",
}

export const apiErrorGeneric: IApiError = {
  errorMessage: ApiErrorMessages.Generic,
};
const apiErrorUnauthorized: IApiError = {
  errorMessage: ApiErrorMessages.Unauthorized,
};

const axiosConfiguration = {
  baseURL: baseApiUrl,
  xsrfHeaderName: csrfHeaderName,
  xsrfCookieName: csrfCookieName,
  withCredentials: true,
};

const getCsrfToken = async (): Promise<string> => {
  /**
   * Get a CSRF token from cookie or server.
   */
  // Check if a CSRF token is already present in a cookie.
  let csrfToken: string | undefined = document.cookie
    ?.split("; ")
    .find((_cookie) => _cookie.startsWith(`${csrfCookieName}=`))
    ?.split("=")[1];
  // If CSRF token is not present in a cookie, request one from the server.
  if (!csrfToken) {
    await axios.get(`${baseUrl}/csrf/`, axiosConfiguration).then((response) => {
      csrfToken = response.data?.csrfToken;
    });
  }
  // Return the CSRF token.
  return csrfToken ? csrfToken : "";
};

export const getAxiosInstance = async (
  isFileUpload: boolean = false,
  isUserManagementRelated: boolean = false,
): Promise<AxiosInstance> => {
  /**
   * Get an Axios instance with the correct configuration.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const headers: { [key: string]: any } = {
    "Content-Type": isFileUpload ? "multipart/form-data" : "application/json",
  };
  // Add the CSRF header.
  await getCsrfToken().then((csrfToken) => {
    headers[csrfHeaderName] = csrfToken;
  });
  const axiosConfiguration = {
    baseURL: isUserManagementRelated ? baseUserManagementUrl : baseApiUrl,
    headers: headers,
    xsrfHeaderName: csrfHeaderName,
    xsrfCookieName: csrfCookieName,
    withCredentials: true,
  };
  return axios.create(axiosConfiguration);
};

export const handleAxiosError = async (
  error: AxiosError,
): Promise<IApiError> => {
  /**
   * Handling an Axios error.
   */
  // Handle server-returned errors.
  if (error?.response?.status) {
    if ([401, 409].includes(error.response.status)) {
      // Unauthorized, so logout.
      await Auth.logout().then(() => {
        return Promise.reject(apiErrorUnauthorized);
      });
    } else if (error.response.status === 400) {
      // Bad request, so return error data (e.g. form validation errors).
      const apiError: IApiError = {
        errorMessage: ApiErrorMessages.Generic,
        errorData: error.response?.data,
      };
      return Promise.reject(apiError);
    } else {
      // Any other (internal server or unexpected) errors.
      return Promise.reject(apiErrorGeneric);
    }
  }
  // Handle network errors.
  else if (error?.code === AxiosError.ERR_NETWORK) {
    // This could also mean that our server is down,
    // but more likely it's a network error on the user's side.
    const apiError: IApiError = {
      errorMessage: ApiErrorMessages.NoConnection,
    };
    return Promise.reject(apiError);
  }
  // Any other (unexpected) errors.
  return Promise.reject(apiErrorGeneric);
};

export const setUserInStorage = async (user: IUser): Promise<null> => {
  // TODO (Rob): Do this in Redux instead.
  if (user) {
    // Set user in LocalStorage.
    localStorage.setItem(LocalStorageKeys.user, JSON.stringify(user));
  }
  return null;
};

export const deleteUserFromStorage = async (): Promise<null> => {
  // TODO (Rob): Do this in Redux instead.
  // Delete user from localStorage.
  localStorage.removeItem(LocalStorageKeys.user);
  return null;
};

export const getUserFromStorage = async (): Promise<IUser | null> => {
  // TODO (Rob): Do this in Redux instead.
  // Retrieve user from localStorage.
  const userData: string | null = localStorage.getItem(LocalStorageKeys.user);
  return userData ? JSON.parse(userData) : null;
};

export const setOrganizationInStorage = async (
  organization: IOrganization,
): Promise<null> => {
  // TODO (Rob): Do this in Redux instead.
  if (organization) {
    // Set organization in LocalStorage.
    localStorage.setItem(
      LocalStorageKeys.organization,
      JSON.stringify(organization),
    );
  }
  return null;
};

export const deleteOrganizationFromStorage = async (): Promise<null> => {
  // TODO (Rob): Do this in Redux instead.
  // Delete organization from localStorage.
  localStorage.removeItem(LocalStorageKeys.organization);
  return null;
};

export const getOrganizationFromStorage =
  async (): Promise<IOrganization | null> => {
    // TODO (Rob): Do this in Redux instead.
    // Retrieve organization from localStorage.
    const organizationData: string | null = localStorage.getItem(
      LocalStorageKeys.organization,
    );
    return organizationData ? JSON.parse(organizationData) : null;
  };

export const handleDownload = async (
  documentData: IDocument,
  documentDownloadUrl: string,
) => {
  // FIXME (Rob): Handle this neater.
  const link = document.createElement("a");
  link.href = URL.createObjectURL(
    // @ts-expect-error: responseType is not a valid property for get
    await Api.get(documentDownloadUrl, { responseType: "blob" }),
  );
  link.download = documentData.name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
