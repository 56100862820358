import React from "react";
import logo from "../../assets/images/logo192.png";

export default function AuthCardHeader(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any,
): React.JSX.Element {
  const title: string = props.title;
  const isScreenLg: boolean = props.isScreenLg;
  return (
    <div className={"mx-2"}>
      <div className={"d-flex justify-content-between align-items-center mt-3"}>
        {isScreenLg ? (
          <img
            alt="Manual logo"
            title={"Manual logo"}
            src={logo}
            width="38"
            height="38"
          />
        ) : (
          <></>
        )}
        <h5 className={"m-0 mx-2 w-100 text-center text-truncate"}>
          <small>{title}</small>
        </h5>
        {isScreenLg ? (
          <div style={{ width: "38px", height: "38px" }}></div>
        ) : (
          <></>
        )}
      </div>
      <hr className={"mt-4 mb-0"} />
    </div>
  );
}
