import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeft, CloudArrowDown } from "react-bootstrap-icons";
import { Button, Spinner } from "react-bootstrap";

import { truncateString } from "../../common/Utils";
import config from "../../../config";
import { handleDownload } from "../../common/api/Utils";

export default function ChatsDetailCitation(): React.JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const citationData: ICitation = location?.state?.citation;
  const citationIndex: number = location?.state?.citationIndex;
  const chatTopic: string = location?.state?.chatTopic;
  const [documentDownloadUrl, setDocumentDownloadUrl] =
    React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    setIsLoading(true);
    if (!citationData?.document?.url) {
      // If no citation/document data, redirect to chats overview
      // (this is probably caused by a user copy-pasting the URL).
      return navigate("/dashboard/chats");
    } else {
      // Set the document download URL.
      // To prevent caching issues, append (unused) timestamp query parameter.
      setDocumentDownloadUrl(
        `${config.backendBaseUrl}${citationData.document.url}?timestamp=${new Date().getTime()}`,
      );
    }
    setIsLoading(false);
  }, [navigate, citationData?.document]);

  return (
    <div className={"h-100 d-flex flex-column"}>
      {isLoading ? (
        <div
          className={"h-100 d-flex justify-content-center align-items-center"}
        >
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <>
          <div className={"d-flex align-items-center mb-3 py-1 text-muted"}>
            <Button
              title={"Vorige"}
              name={"Vorige"}
              type="button"
              onClick={() => navigate(-1)}
              className={"d-flex me-2 border-0 bg-transparent text-muted p-0"}
            >
              <ArrowLeft />
            </Button>
            <h6
              onClick={() => navigate("/dashboard/chats")}
              className={"m-0 ms-1 text-decoration-underline"}
              style={{ cursor: "pointer" }}
            >
              Chats
            </h6>
            <h6 className={"m-0 mx-2"}>/</h6>
            <h6
              onClick={() => navigate(-1)}
              className={"m-0 text-decoration-underline text-truncate"}
              style={{ cursor: "pointer" }}
            >
              Chat
              {chatTopic ? (
                <span>
                  : {` `}
                  <i>{truncateString(chatTopic)}</i>
                </span>
              ) : (
                ""
              )}
            </h6>
            <h6 className={"m-0 mx-2"}>/</h6>
            <h6 className={"m-0 text-truncate"}>
              {citationIndex ? `Citatie ${citationIndex}:` : ""}{" "}
              {citationData.document.name}
              {citationData.pageStart && citationData.pageEnd ? (
                <>
                  <> </>
                  {citationData.pageStart === citationData.pageEnd ? (
                    <>(pagina {citationData.pageStart})</>
                  ) : (
                    <>
                      (pagina&apos;s {citationData.pageStart}-
                      {citationData.pageEnd})
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </h6>
          </div>

          <div
            className="h-100 bg-light rounded-3 border border-1 border-light-subtle z-1 position-relative"
            style={{ minHeight: "15rem" }}
          >
            <div
              className={
                "d-flex w-100 h-100 justify-content-center align-items-center mt-3 z-2 position-absolute"
              }
            >
              <p className={"text-truncate mx-3"}>
                {citationData?.document?.name}
              </p>
            </div>
            {["pdf", "txt"].includes(citationData?.document?.extension) &&
            documentDownloadUrl ? (
              <embed
                className={"rounded-3 position-absolute z-3"}
                src={`${documentDownloadUrl}#page=${citationData.pageStart ? citationData.pageStart : 1}`}
                width="100%"
                height="100%"
              />
            ) : (
              <></>
            )}
          </div>
          <div className={"d-flex justify-content-end mt-3"}>
            <Button
              title={"Document downloaden"}
              variant="dark"
              type="button"
              onClick={() =>
                handleDownload(citationData?.document, documentDownloadUrl)
              }
              className={"d-flex align-items-center rounded-3"}
            >
              <CloudArrowDown className={"me-2"} />
              Document downloaden
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
