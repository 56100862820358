import React, { useEffect } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { BoxArrowRight, CheckCircleFill } from "react-bootstrap-icons";

import ErrorAlert from "../../common/ErrorAlert";
import AuthCardFooter from "../../common/AuthCardFooter";
import {
  getOrganizationFromStorage,
  getUserFromStorage,
} from "../../common/api/Utils";
import AuthCardHeader from "../../common/AuthCardHeader";
import { Auth } from "../../common/api/Auth";
import { useAppSelector } from "../../../hooks";

export default function Account(): React.JSX.Element {
  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);

  const [userData, setUserData] = React.useState<IUser | undefined>();
  const [organizationData, setOrganizationData] =
    React.useState<IOrganization | null>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [apiError, setApiError] = React.useState<IApiError | undefined>();

  useEffect(() => {
    setIsLoading(true);
    // Load the user data, on first load.
    getOrganizationFromStorage().then((organization: IOrganization | null) => {
      setOrganizationData(organization);
      getUserFromStorage().then((user: IUser | null) => {
        setUserData(user ? user : undefined);
        setIsLoading(false);
      });
    });
  }, []);

  return (
    <div className={"h-100 d-flex flex-column"}>
      <div className={"d-flex align-items-center mb-3 py-1 text-muted"}>
        <h6 className={"m-0"}>Account</h6>
      </div>

      {apiError ? (
        <ErrorAlert apiError={apiError} />
      ) : (
        <div className={"d-flex justify-content-center align-items-center"}>
          {isLoading ? (
            <div className={"d-flex justify-content-center align-items-center"}>
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <Card
              className={"rounded-3 border-1 border-light-subtle bg-light mt-0"}
              style={{ width: "100%", maxWidth: "25rem" }}
            >
              <Card.Header className={"rounded-top-3 border-0 bg-light"}>
                <AuthCardHeader title={"Account"} isScreenLg={isScreenLg} />
              </Card.Header>

              <Card.Body className={"bg-light px-4 pb-4"}>
                <div className="mb-3">
                  <div className={"small text-muted my-1"}>Organisatie</div>
                  <p>{organizationData?.name}</p>
                </div>

                <div className="mb-3">
                  <div className={"small text-muted my-1"}>Email</div>
                  <p>{userData?.email}</p>
                </div>

                {userData?.hasAdminRights ? (
                  <div className="mb-3">
                    <div className={"small text-muted my-1"}>
                      Beheerdersrechten
                    </div>
                    <p
                      className={"text-success"}
                      title={"Account heeft beheerdersrechten"}
                    >
                      <CheckCircleFill />
                    </p>
                  </div>
                ) : (
                  <></>
                )}

                <div className={"d-flex justify-content-end mt-4"}>
                  <Button
                    title={"Uitloggen"}
                    variant="dark"
                    type="submit"
                    onClick={() => Auth.logout()}
                    className={
                      "d-flex align-items-center justify-content-start rounded-3"
                    }
                  >
                    <BoxArrowRight className={"me-2"} />
                    <span>Uitloggen</span>
                  </Button>
                </div>
              </Card.Body>

              <Card.Footer className="rounded-bottom-3 d-flex align-items-center justify-content-between text-muted border-0 py-3">
                <AuthCardFooter />
              </Card.Footer>
            </Card>
          )}
        </div>
      )}
    </div>
  );
}
