import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

import { Api } from "../../common/api/Api";
import { dateTimeToStr, strToDateTime } from "../../common/Utils";
import ErrorAlert from "../../common/ErrorAlert";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { setChats } from "../../../features/recentChatsSlice";

const dateTimeFormatter = (dt: string): string => {
  return dateTimeToStr(strToDateTime(dt), "NL");
};

const topicFormatter = (
  s: string | undefined,
  maxChars: number | null = null,
): string => {
  if (!s) {
    return "Geen onderwerp";
  } else {
    if (maxChars === null) {
      return s;
    }
    if (s.length > maxChars) {
      return s.substring(0, maxChars) + "...";
    }
    return s;
  }
};

const ChatItem = (props: { chat: IChatRowData }): React.JSX.Element => {
  return (
    <Link
      to={`/dashboard/chats/chat`}
      state={{ chat: props.chat }}
      className={"mb-2 link-underline link-underline-opacity-0"}
    >
      <OverlayTrigger
        placement={"right"}
        overlay={
          <Tooltip id={props.chat.id} className={"ms-3"}>
            <div className={"py-1"}>
              <p>{topicFormatter(props.chat.topic, 60)}</p>
              <small>Gestart op {dateTimeFormatter(props.chat.created)}</small>
            </div>
          </Tooltip>
        }
      >
        <div className={"px-2 rounded-3 nav-link"}>
          <div className={"d-flex align-items-center justify-content-start"}>
            <span className={"small text-truncate"}>
              {topicFormatter(props.chat.topic)}
            </span>
          </div>
        </div>
      </OverlayTrigger>
    </Link>
  );
};

export default function MostRecentChats(): React.JSX.Element {
  const rowData = useAppSelector((state) => state.recentChats.value);
  const dispatch = useAppDispatch();

  const [apiError, setApiError] = React.useState<IApiError | undefined>();

  const getChatsData = async () => {
    await Api.get("chats/most_recent").then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: { [key: string]: any }) => {
        const rows: IChatRowData[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response.results.forEach((chatData: any) => {
          const row = {
            id: chatData.id,
            topic: chatData.topic,
            created: dateTimeToStr(new Date(chatData.created), "US"),
            modified: dateTimeToStr(new Date(chatData.modified), "US"),
          };
          rows.push(row);
        });
        dispatch(setChats(rows));
      },
      (error: IApiError) => {
        setApiError(error);
      },
    );
  };

  React.useEffect(() => {
    getChatsData().then(() => {});
  }, []);

  // Show nothing if there are no chats yet, not even the header.
  if (rowData.length === 0) {
    return <></>;
  }

  return (
    <div className={"h-100 d-flex flex-column"}>
      <div className={"d-flex align-items-center mb-2 py-1 text-muted"}>
        <p className={"m-0 small text-nowrap overflow-hidden"}>
          Meest recente chats
        </p>
      </div>

      {apiError ? (
        <ErrorAlert apiError={apiError} />
      ) : (
        <>
          {rowData.map((chat: IChatRowData) => (
            <ChatItem key={chat.id} chat={chat} />
          ))}
        </>
      )}
    </div>
  );
}
