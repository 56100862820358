interface IConfig {
  backendBaseUrl: string;
}

const config: IConfig = {
  backendBaseUrl:
    window._env.REACT_APP_BACKEND_BASE_URL || "http://localhost:8000",
};

export default config;
