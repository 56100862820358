import { createSlice } from "@reduxjs/toolkit";
import { BREAKPOINT_SCREEN_WIDTH_LG } from "../constants";

const getIsScreenLg = (): boolean => {
  /**
   * Indicates whether the screen is LG (or larger),
   * i.e. not a mobile screen.
   */
  return window.innerWidth >= BREAKPOINT_SCREEN_WIDTH_LG;
};

interface IsScreenLgState {
  value: boolean;
}

const defaultState: IsScreenLgState = {
  value: getIsScreenLg(),
};

export const isScreenLgSlice = createSlice({
  name: "isScreenLg",
  initialState: defaultState,
  reducers: {
    updateIsScreenLg(state) {
      state.value = getIsScreenLg();
    },
  },
});

export const { updateIsScreenLg } = isScreenLgSlice.actions;
export default isScreenLgSlice.reducer;
