import React, { useEffect } from "react";
import { hasAdminRights, isAuthenticatedIndication } from "./api/Auth";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";

export const NoDocuments = (): React.JSX.Element => {
  const [isAdministrator, setIsAdministrator] = React.useState<boolean>(false);

  useEffect(() => {
    isAuthenticatedIndication().then((isAuthenticated: boolean) => {
      if (isAuthenticated) {
        hasAdminRights().then((isAdmin: boolean) => {
          setIsAdministrator(isAdmin);
        });
      }
    });
  }, []);

  const message = isAdministrator ? (
    <Link to={"/dashboard/documents/upload"} className={"link-dark"}>
      Upload ten minste één document.
    </Link>
  ) : (
    <p>Contacteer uw beheerder(s) om ten minste één document te uploaden</p>
  );

  return (
    <Alert variant={"secondary"} className={"rounded-3"}>
      <h4>Geen documenten</h4>
      {message}
    </Alert>
  );
};
