import React from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ValueFormatterParams } from "ag-grid-community";

import ChatPlus from "../../../assets/images/chatPlus.svg";
import { Api } from "../../common/api/Api";
import { dateTimeToStr, strToDateTime } from "../../common/Utils";
import ErrorAlert from "../../common/ErrorAlert";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { addChat } from "../../../features/recentChatsSlice";
import { NoDocuments } from "../../common/NoDocuments";
import { MAX_ITEMS_WITHOUT_PAGINATION } from "../../../constants";

export default function ChatsList(): React.JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Reference to the grid, so we can access its API methods.
  const gridRef = React.useRef<AgGridReact<IChatRowData>>(null);
  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);

  const [apiError, setApiError] = React.useState<IApiError | undefined>();
  const [haveDocuments, setHaveDocuments] = React.useState<boolean>(false);
  const [isGridReady, setIsGridReady] = React.useState<boolean>(false);

  const handleChatView = (currentRowData: IChatRowData): void => {
    /**
     * View the chat from the row data.
     */
    navigate("/dashboard/chats/chat", { state: { chat: currentRowData } });
  };

  const handleChatNew = (): void => {
    /**
     * Start a new chat.
     */
    // Show loading indicator.
    setIsLoading(true);
    setRowData([]);
    // Create a new chat.
    Api.post("chats/", {}).then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: { [key: string]: any }) => {
        const newChatRowData: IChatRowData = {
          id: response.id,
          topic: "",
          created: dateTimeToStr(new Date(response.created), "US"),
          modified: dateTimeToStr(new Date(response.modified), "US"),
        };
        // View the newly created chat.
        handleChatView(newChatRowData);
        dispatch(addChat(newChatRowData));
      },
      (error: IApiError) => {
        setApiError(error);
      },
    );
  };

  const topicFormatter = (params: ValueFormatterParams): string => {
    if (!params.value) {
      return "Geen onderwerp";
    } else {
      return params.value;
    }
  };

  const dateTimeFormatter = (
    params: ValueFormatterParams,
  ): string | undefined => {
    if (params.value) {
      return dateTimeToStr(strToDateTime(params.value), "NL");
    }
  };

  const columnDefs = [
    {
      field: "topic",
      headerName: "Onderwerp",
      flex: 9,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onCellClicked: (e: any) => handleChatView(e.data),
      cellStyle: { cursor: "pointer", textDecoration: "underline" },
      cellClass: "text-truncate",
      valueFormatter: topicFormatter,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
        filterPlaceholder: "Zoek in chats...",
        filterOptions: ["contains"],
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
    },
    {
      field: "created",
      headerName: "Gestart",
      sort: "desc",
      valueFormatter: dateTimeFormatter,
      flex: 3,
    },
  ];
  const [rowData, setRowData] = React.useState<IChatRowData[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const getChatsData = async () => {
    await Api.getPaginated("chats/").then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: { [key: string]: any }) => {
        const chatRowData: IChatRowData[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response.forEach((chatData: any) => {
          chatRowData.push({
            id: chatData.id,
            topic: chatData.topic,
            created: dateTimeToStr(new Date(chatData.created), "US"),
            modified: dateTimeToStr(new Date(chatData.modified), "US"),
          });
        });
        setRowData(chatRowData);
      },
      (error: IApiError) => {
        setApiError(error);
      },
    );
  };

  React.useEffect(() => {
    setIsLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Api.get("documents/any/").then((response: { [key: string]: any }) => {
      if (response.exists && response.exists === true) {
        setHaveDocuments(true);
        getChatsData().then(() => {
          setIsLoading(false);
        });
      } else {
        // No documents, we don't need to get chat data anymore
        setHaveDocuments(false);
        setIsLoading(false);
      }
    });
  }, []);

  React.useEffect(() => {
    /**
     * Hide certain columns on smaller screens.
     */
    if (isGridReady) {
      if (isScreenLg) {
        gridRef.current?.api?.setColumnsVisible(["created"], true);
      } else {
        gridRef.current?.api?.setColumnsVisible(["created"], false);
      }
    }
  }, [isGridReady, isScreenLg]);

  const NoRowsOverlayComponent = (props: { isLoading: boolean }) => {
    return (
      <div style={{ pointerEvents: "auto" }}>
        {props.isLoading ? (
          <Spinner animation="border" variant="secondary" />
        ) : (
          <p>Geen chats.</p>
        )}
      </div>
    );
  };

  return (
    <div className={"h-100 d-flex flex-column"}>
      <div className={"d-flex align-items-center mb-3 py-1 text-muted"}>
        <h6 className={"m-0"}>Chats</h6>
      </div>

      {apiError ? (
        <ErrorAlert apiError={apiError} />
      ) : !haveDocuments && !isLoading ? (
        <NoDocuments />
      ) : (
        <>
          <div className="ag-theme-quartz h-100" style={{ minHeight: "15rem" }}>
            <AgGridReact<IChatRowData>
              ref={gridRef}
              onGridReady={() => setIsGridReady(true)}
              // @ts-expect-error:next-line
              columnDefs={columnDefs}
              rowData={rowData}
              reactiveCustomComponents={true}
              noRowsOverlayComponent={NoRowsOverlayComponent}
              noRowsOverlayComponentParams={{ isLoading: isLoading }}
              suppressCellFocus={true}
              pagination={rowData.length > MAX_ITEMS_WITHOUT_PAGINATION}
            />
          </div>

          <div className={"d-flex justify-content-end mt-3"}>
            <Button
              title={"Nieuwe chat"}
              variant="light"
              type="button"
              onClick={handleChatNew}
              className={
                "d-flex align-items-center rounded-3 border-1 border-light-subtle"
              }
              disabled={isLoading}
            >
              <img src={ChatPlus} className={"me-2"} alt={"Nieuwe chat icon"} />
              Nieuwe chat
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
