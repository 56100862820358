import React, { FormEvent } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Send, PersonPlus } from "react-bootstrap-icons";

import { Api } from "../../common/api/Api";

export default function UsersAdd(): React.JSX.Element {
  /**
   * Select and invite users to the organization.
   */
  const [formDataEmail, setFormDataEmail] = React.useState<string>("");
  const [apiError, setApiError] = React.useState<IApiError | undefined>();
  const [showSuccessPage, setShowSuccessPage] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    setFormDataEmail("");
    setShowSuccessPage(false);
  };

  const submitForm = async (e: FormEvent): Promise<void> => {
    /**
     * Invite a user via email.
     */
    e.preventDefault();
    // Clear previous errors and success messages, if any.
    setApiError(undefined);
    // Invite the user.
    await Api.post("invitations/send-invite/", [formDataEmail]).then(
      () => {
        setShowSuccessPage(true);
      },
      (error: IApiError) => {
        setApiError(error);
      },
    );
  };

  return (
    <div>
      <Button
        title={"Gebruiker uitnodigen"}
        variant="light"
        type="button"
        onClick={handleShow}
        className={
          "d-flex align-items-center rounded-3 border-1 border-light-subtle"
        }
      >
        <PersonPlus className={"me-2"} />
        Gebruiker uitnodigen
      </Button>

      <Modal
        centered={true}
        show={showModal}
        onHide={handleClose}
        contentClassName={"bg-white rounded-3 border-1 border-light-subtle"}
      >
        <Modal.Header closeButton={true} className={"p-3 border-0"}>
          <Modal.Title>
            <h5 className={"mb-0"}>Gebruiker uitnodigen</h5>
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={submitForm}>
          <Modal.Body className={"p-3 border-0"}>
            {showSuccessPage ? (
              <p className={"m-0"}>
                Een uitnodiging is verstuurd naar <i>{formDataEmail}</i>.
                Wanneer de uitnodiging is geaccepteerd, wordt{" "}
                <i>{formDataEmail}</i> toegevoegd aan de lijst van gebruikers.
                <br />
                <br />
                <small>De uitnodiging is 31 dagen geldig.</small>
              </p>
            ) : (
              <Form.Group className="mb-2">
                <p className={"mb-3"}>
                  Vul hier de email in van de nieuwe gebruiker om deze een
                  uitnodiging te sturen.
                </p>
                <Form.Label column={false} className={"small text-muted mb-1"}>
                  Email
                </Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={formDataEmail}
                  onChange={(e) => setFormDataEmail(e.target.value)}
                  required={true}
                  autoFocus={true}
                  className={
                    "rounded-pill py-2 px-3 border-2 border-light-subtle"
                  }
                />
                {apiError?.errorData?.invalid?.map(
                  (
                    errorMessage: {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      [key: string]: any;
                    },
                    _index: number,
                  ) => {
                    let errorCode: string = errorMessage[formDataEmail];
                    if (errorCode === "invalid email") {
                      errorCode = "Ongeldige email.";
                    } else if (["pending invite"].includes(errorCode)) {
                      errorCode =
                        "Er is al een uitnodiging verstuurd naar deze gebruiker.";
                    } else if (
                      ["user registered email", "already accepted"].includes(
                        errorCode,
                      )
                    ) {
                      errorCode = "Deze gebruiker bestaat al.";
                    }
                    return (
                      <Form.Text
                        key={`error-${errorMessage.param}-${_index}`}
                        className="text-danger"
                      >
                        {errorCode}
                        <br />
                      </Form.Text>
                    );
                  },
                )}
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer className={"p-3 border-0"}>
            {showSuccessPage ? (
              <Button
                title={"Nog een gebruiker uitnodigen"}
                variant="dark"
                type="button"
                onClick={() => {
                  setFormDataEmail("");
                  setShowSuccessPage(false);
                }}
                className={"rounded-3 border-1 border-light-subtle m-0"}
              >
                Nog een gebruiker uitnodigen
              </Button>
            ) : (
              <Button
                title={"Uitnodiging versturen"}
                variant="dark"
                type="submit"
                disabled={!formDataEmail}
                className={
                  "d-flex align-items-center rounded-3 border-1 border-light-subtle m-0"
                }
              >
                <Send className={"me-2"} />
                Uitnodiging versturen
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
