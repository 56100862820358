import { configureStore } from "@reduxjs/toolkit";
import recentChatsReducer from "./features/recentChatsSlice";
import isScreenLgReducer from "./features/isScreenLgSlice";

const store = configureStore({
  reducer: {
    recentChats: recentChatsReducer,
    isScreenLg: isScreenLgReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
